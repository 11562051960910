// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Upload, message, Button as AntdButton, Rate, Pagination } from 'antd';
import axios from 'axios';
import { RcFile, UploadChangeParam } from 'antd/es/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { RxCross1 } from 'react-icons/rx';

import NoImage from '../../../../assets/no-user-image.png';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Button from '../../../../components/bootstrap/Button';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import {
	usePostOnBoardingMutation,
	useGetPresignedUrlMutation,
} from '../../../../services/OnBoardingApis/OnBoardingApi';
import {
	ApiResponse,
	CategoryData,
	CategoryResponse,
	MasseusesData,
	MasseusesListResponse,
	PresignedResponse,
	ServiceData,
	ServiceResponse,
} from '../../../../services/DashboardApis/type';
import { OnBoardingResponse } from '../../../../services/OnBoardingApis/type';
import {
	setOnBoardingStep,
	setOnBoardServiceServiceProvidersCurrentPage,
} from '../../../../redux/slice';
import {
	useAddServiceMutation,
	useGetCategoriesMutation,
	useGetMasseuseListMutation,
	useGetServicesMutation,
	useUpdateServiceMutation,
} from '../../../../services/DashboardApis/dashboardApi';
import Loader from '../../../../components/Loader';
import { RootState } from '../../../../redux/store';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Avatar from '../../../../components/Avatar';
import useDarkMode from '../../../../hooks/useDarkMode';
import { truncateString } from '../../../../utils/truncate';
import Spinner from '../../../../components/bootstrap/Spinner';
import NoDataFound from '../../../../assets/svg/NoDataFound';

export interface Availability {
	day: string;
	time: {
		startTime: string;
		endTime: string;
	};
}
interface ServiceForm {
	category: string;
	name: string;
	serviceImage: string;
	description: string;
	pricingData: {
		duration: string;
		price: string;
	}[];
	availability: Availability[];
	masseuses: string[];
}

const uploadButton = (
	<button style={{ border: 0, background: 'none' }} type='button'>
		<PlusOutlined />
		<div style={{ marginTop: 8 }}>Upload</div>
	</button>
);

const OnBoardServiceManagement = () => {
	const dispatch = useDispatch();
	const { darkModeStatus } = useDarkMode();
	const [fileData, setFileData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [serviceData, setServiceData] = useState<any>({
		category: '',
		name: '',
		serviceImage: '',
		description: '',
		masseuses: [],
		pricingData: [{ price: 0, duration: 0 }],
		availability: [],
	});
	const [categories, setCategories] = useState<CategoryData[]>();
	const [serviceForms, setServiceForms] = useState<ServiceForm[]>([
		{
			category: '',
			name: '',
			serviceImage: '',
			description: '',
			pricingData: [{ duration: '', price: '' }],
			availability: [{ day: '', time: { startTime: '', endTime: '' } }],
			masseuses: [],
		},
	]);
	const [selectedFile, setSelectedFile] = useState<RcFile | null>(null);
	const [masseuses, setMasseuses] = useState<MasseusesData[]>();
	const [services, setServices] = useState<ServiceData[]>();
	const [masseuseResponse, setMasseuseResponse] = useState<MasseusesListResponse | null>(null);
	const [serviceResponse, setServiceResponse] = useState<ServiceResponse | null>(null);
	const [isSaveComplete, setIsSaveComplete] = useState(false);
	const { id } = useSelector((state: RootState) => state.admin);
	const onBoardServiceServiceProvidersCurrentPage =
		useSelector((state: RootState) => state.admin.onBoardServiceServiceProvidersCurrentPage) ||
		1;
	const daysOfWeek = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];

	const [getCategories] = useGetCategoriesMutation();
	const [addService] = useAddServiceMutation();
	const [postOnBoarding] = usePostOnBoardingMutation();
	const [getPresignedUrl] = useGetPresignedUrlMutation();
	const [getMasseuseList] = useGetMasseuseListMutation();
	const [getServices] = useGetServicesMutation();
	const [updateService] = useUpdateServiceMutation();

	// *************************************************************
	// NOTE: Helper Methods
	// *************************************************************
	const addServiceForm = () => {
		setServiceForms([
			...serviceForms,
			{
				category: '',
				name: '',
				serviceImage: '',
				description: '',
				pricingData: [{ duration: '', price: '' }],
				availability: [],
				masseuses: [],
			},
		]);
	};

	const removeServiceForm = (index: number) => {
		const updatedForms = [...serviceForms];
		updatedForms.splice(index, 1);
		setServiceForms(updatedForms);
	};

	const handleDeleteForm = (index: number) => {
		setServiceForms((prevData) => prevData.filter((_, i) => i !== index));
	};

	const handleInputChange = (index: number, field: keyof ServiceForm, value: any) => {
		const updatedForms = [...serviceForms];
		updatedForms[index][field] = value;
		setServiceForms(updatedForms);
	};

	const handlePricingChange = (
		formIndex: number,
		pricingIndex: number,
		field: keyof ServiceForm['pricingData'][0],
		value: any,
	) => {
		const updatedForms = [...serviceForms];
		updatedForms[formIndex].pricingData[pricingIndex][field] = value;
		setServiceForms(updatedForms);
	};

	const handleAvailabilityChange = (
		formIndex: number,
		availabilityIndex: number,
		field: 'day' | 'time',
		value: any,
		subField?: 'startTime' | 'endTime',
	) => {
		const updatedForms = [...serviceForms];
		const availability = updatedForms[formIndex].availability[availabilityIndex];

		if (field === 'time' && subField) {
			const { startTime, endTime } = availability.time;

			availability.time[subField] = value;

			const updatedStartTime = subField === 'startTime' ? value : startTime;
			const updatedEndTime = subField === 'endTime' ? value : endTime;

			if (updatedStartTime && updatedEndTime) {
				const startDateTime = new Date(`1970-01-01T${updatedStartTime}`);
				const endDateTime = new Date(`1970-01-01T${updatedEndTime}`);

				if (endDateTime <= startDateTime) {
					message.warning(
						'End time must be greater than start time and cannot be the same.',
					);
					return;
				}
			}
		} else {
			availability[field] = value;
		}

		setServiceForms(updatedForms);
	};

	const handleCardClick = (formIndex: number, masseuseId: string) => {
		setServiceForms((prevServiceForms) => {
			return prevServiceForms.map((service, index) => {
				if (index === formIndex) {
					const isSelected = service.masseuses.includes(masseuseId);

					return {
						...service,
						masseuses: isSelected
							? // eslint-disable-next-line @typescript-eslint/no-shadow
								service.masseuses.filter((id) => id !== masseuseId)
							: [...service.masseuses, masseuseId],
					};
				}
				return service;
			});
		});
	};

	const addPricingInput = (formIndex: number) => {
		const updatedForms = [...serviceForms];
		const newPricingInput = {
			price: '',
			duration: '',
		};

		updatedForms[formIndex].pricingData.push(newPricingInput);
		setServiceForms(updatedForms);
	};

	const deletePricing = (formIndex: number, pricingIndex: number) => {
		const updatedForms = [...serviceForms];
		updatedForms[formIndex].pricingData.splice(pricingIndex, 1);
		setServiceForms(updatedForms);
	};

	const deleteAvailability = (formIndex: number, availabilityIndex: number) => {
		const updatedForms = [...serviceForms];
		updatedForms[formIndex].availability.splice(availabilityIndex, 1);
		setServiceForms(updatedForms);
	};

	const uploadDocumentsHandler = async (
		info: { file: RcFile; fileList: RcFile[] },
		index: number,
	): Promise<boolean> => {
		const selectedDoc = info.file;
		setFileData(info.fileList);

		if (!selectedDoc) {
			message.error('Selected document is undefined');
			return false;
		}

		try {
			const response: any = await getPresignedUrl({
				filename: selectedDoc.name,
				keyType: 'serviceImage',
			}).unwrap();

			await updateDocumentHandler({
				presignedData: response.data,
				selectedDoc,
				index,
			});

			message.success('Successfully uploaded document');
			return true;
		} catch (err: any) {
			message.error('Document upload failed');
			return false;
		}
	};

	const handleAddAvailability = (formIndex: number) => {
		const updatedForms = [...serviceForms];
		const newAvailability = {
			day: '',
			time: {
				startTime: '',
				endTime: '',
			},
		};

		updatedForms[formIndex].availability.push(newAvailability);
		setServiceForms(updatedForms);
	};

	const getCategoryHandler = useCallback(async () => {
		setIsLoading(true);
		await getCategories({
			isAllCategories: true,
		})
			.unwrap()
			.then((response) => {
				const { result, message: msg, data } = response as CategoryResponse;
				if (result) {
					setCategories(data);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				message.error(error?.data?.message);
				console.error(error);
				setIsLoading(false);
			});
	}, [getCategories]);

	const getMasseusesHandler = useCallback(async () => {
		setIsLoading(true);
		await getMasseuseList({
			centerId: id,
			currentPage: onBoardServiceServiceProvidersCurrentPage,
		})
			.unwrap()
			.then(async (response) => {
				const {
					result,
					data: masseuse,
					message: msg,
					pagination,
				} = response as MasseusesListResponse;
				if (masseuse) {
					setMasseuseResponse({ result, data: masseuse, message: msg, pagination });
					setMasseuses(masseuse);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log('🚀  getMasseuseData  err:', err);
				setIsLoading(false);
				message.error(err?.data?.message);
			});
	}, [getMasseuseList, id, onBoardServiceServiceProvidersCurrentPage]);

	const getPreviousServiceHandler = useCallback(async () => {
		setIsLoading(true);
		await getServices({
			centerId: id,
			isAllServices: true,
		})
			.unwrap()
			.then(async (res) => {
				const {
					result,
					message: msg,
					data: serviceList,
					pagination,
				} = res as ServiceResponse;
				if (serviceList) {
					setTimeout(() => {
						setServiceResponse({ result, data: serviceList, message: msg, pagination });
						setServices(serviceList);
						setIsLoading(false);
					}, 500);
				}
			})
			.catch(() => {
				message.error('Error in data fetching');
			});
	}, [getServices, id]);

	const deleteServiceHandler = async (serviceId: string) => {
		setIsDeleteLoading(true);
		await updateService({ serviceId, payload: { isArchived: true } })
			.unwrap()
			.then((response) => {
				const { result, message: msg } = response as ApiResponse;
				if (result) {
					message.success(msg);
					getPreviousServiceHandler();
					setIsDeleteLoading(false);
				}
			})
			.catch((error) => {
				message.error(error?.data?.message);
				setIsDeleteLoading(false);
			});
	};

	const updateDocumentHandler = async ({
		presignedData,
		selectedDoc,
		index,
	}: {
		presignedData: PresignedResponse;
		selectedDoc: RcFile;
		index: number;
		// eslint-disable-next-line consistent-return
	}) => {
		if (!presignedData && !selectedDoc) {
			return message.error('Something went wrong please try again later');
		}
		setServiceData({ ...serviceData, profileImage: presignedData?.filename });

		const updatedForms = [...serviceForms];
		// eslint-disable-next-line @typescript-eslint/dot-notation
		updatedForms[index]['serviceImage'] = presignedData?.filename;
		setServiceForms(updatedForms);

		await axios
			.put(`${presignedData.presignedUrl}`, selectedDoc)
			.then(() => {
				message.success('Successfully uploaded');
			})
			.catch(() => {
				message.error('Something went wrong...');
			});
	};

	const handleThirdStepSubmit = () => {
		try {
			postOnBoarding({
				onboardingStep: 3,
			})
				.unwrap()
				.then(async (res) => {
					const { result, message: msg, data } = res as OnBoardingResponse;
					if (result) {
						message.success(msg);
						dispatch(setOnBoardingStep(4));
					} else {
						message.error(msg);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
				});
		} catch (error) {
			message.error('something went wrong');
			console.log(error);
		}
	};

	const addServiceHandler = async (service: ServiceForm) => {
		setIsLoading(true);
		await addService({ payload: service, centerId: id })
			.unwrap()
			.then((response) => {
				const { result, message: msg } = response as ServiceResponse;
				if (result) {
					message.success(msg);
					setIsSaveComplete(true);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				message.error(error?.data?.message);
				setIsLoading(false);
			});
	};

	const handleSaveButtonClick = async (index: number, service: ServiceForm) => {
		if (!selectedFile) {
			message.error('Please select a file before saving.');
			return;
		}

		const invalidDuration = service.pricingData.some((item: any) => item.duration < 30);
		if (invalidDuration) {
			message.error('Each service duration must be at least 30 minutes');
			return;
		}

		setIsLoading(true);

		const uploadSuccess = await uploadDocumentsHandler(
			{
				file: selectedFile,
				fileList: fileData,
			},
			index,
		);

		if (uploadSuccess) {
			await addServiceHandler(service);
		} else {
			message.error('Service could not be saved due to document upload failure');
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getCategoryHandler();
	}, [getCategoryHandler]);

	useEffect(() => {
		getMasseusesHandler();
	}, [getMasseusesHandler]);

	useEffect(() => {
		getPreviousServiceHandler();
	}, [getPreviousServiceHandler]);

	return (
		<div className='row col-12 d-flex justify-content-center'>
			{isLoading ? (
				<Loader />
			) : (
				<div className='d-flex flex-column col-10 align-items-center justify-content-center py-5'>
					<p className='fs-3 mb-0 fw-semibold col-12 text-start'>Add Service</p>
					<div className='row col-12 d-flex row-gap-4 justify-content-center'>
						{isLoading ? (
							<div className='col-12'>
								<Card>
									<CardBody>
										<div
											style={{
												height: '40vh',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
											<Loader />
										</div>
									</CardBody>
								</Card>
							</div>
						) : (
							<div>
								{services && services?.length > 0 && (
									<div className='col-12'>
										<Card>
											<h2
												className='fs-4 p-4'
												style={{ fontWeight: 'bolder' }}>
												Added Services
											</h2>
											<CardBody>
												<div
													style={{
														overflowX: 'auto',
														paddingLeft: '16px',
														paddingRight: '16px',
													}}
													className='mt-0 d-flex gap-3'
													id='services'>
													{services &&
														services?.map((service, index) => (
															<div key={index as number}>
																<Card
																	style={{
																		width: '400px',
																		border: '1px solid #EEE',
																	}}>
																	<CardBody>
																		<div className='row g-4 align-items-center'>
																			<div className='col'>
																				<h2>
																					{service?.name}
																				</h2>
																			</div>
																			<div className='col-12'>
																				<img
																					src={`${process.env.REACT_APP_MEDIA_URL}${service?.serviceImage}`}
																					alt='service_img'
																					className='img-fluid'
																					style={{
																						width: '100%',
																						height: '200px',
																						borderRadius:
																							'10px',
																					}}
																				/>
																			</div>
																			<div className='col-12'>
																				<div className='lead'>
																					<span
																						style={{
																							fontWeight:
																								'bold',
																						}}>
																						Service
																						Category :
																					</span>{' '}
																					{
																						service
																							?.category
																							?.name
																					}
																				</div>
																				<div className='lead'>
																					<span
																						style={{
																							fontWeight:
																								'bold',
																						}}>
																						Description
																						:
																					</span>{' '}
																					{truncateString(
																						service?.description,
																						50,
																					)}
																				</div>
																			</div>
																		</div>
																	</CardBody>
																	<CardFooter className='shadow-3d-container'>
																		<Button
																			onClick={() =>
																				deleteServiceHandler(
																					service.id,
																				)
																			}
																			color='dark'
																			className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${
																				darkModeStatus
																					? 'light'
																					: 'dark'
																			}`}
																			size='lg'
																			tag='a'>
																			{isDeleteLoading && (
																				<Spinner
																					isSmall
																					inButton
																					isGrow
																				/>
																			)}
																			Delete service
																		</Button>
																	</CardFooter>
																</Card>
															</div>
														))}
												</div>
											</CardBody>
										</Card>
									</div>
								)}
							</div>
						)}

						{serviceForms.map((service: ServiceForm, formIndex: number) => (
							<div className='d-flex flex-column  align-items-center col-12 px-3 py-4 border border-2 rounded-2'>
								<div className='row g-4 col-12'>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Service Image</p>
										<Upload
											accept='.jpg, .jpeg, .png'
											listType='picture-card'
											beforeUpload={() => false}
											rootClassName='w-10'
											maxCount={1}
											onChange={(info) => {
												setSelectedFile(
													info.fileList[0]?.originFileObj || null,
												); // Store selected file
												setFileData(info.fileList); // If you're managing the file list state separately
											}}>
											{fileData.length >= 1 ? null : uploadButton}
										</Upload>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Name</p>
										<div className='col-12'>
											<FormGroup id='serviceName' isFloating>
												<Input
													value={service.name}
													onChange={(e: any) => {
														handleInputChange(
															formIndex,
															'name',
															e.target.value,
														);
													}}
												/>
											</FormGroup>
										</div>
									</div>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Category</p>
										<div className='col-12'>
											<FormGroup id='serviceCategory' isFloating>
												<select
													className='form-select'
													value={service.category}
													onChange={(e) =>
														handleInputChange(
															formIndex,
															'category',
															e.target.value,
														)
													}>
													<option value=''>Select Category</option>
													{categories?.map((category, index) => (
														/* eslint-disable-next-line react/no-array-index-key */
														<option key={index} value={category.id}>
															{category.name}
														</option>
													))}
												</select>
											</FormGroup>
										</div>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<p className='m-lg-1 fw-bold'>Description</p>
									<div className='col-12 mt-0'>
										<FormGroup id='description' className=''>
											<Textarea
												value={service?.description}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) =>
													handleInputChange(
														formIndex,
														'description',
														e.target.value,
													)
												}
											/>
										</FormGroup>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12 d-flex justify-content-center row-gap-2'>
									<div className='row col-12 d-flex flex-row justify-content-between align-items-center'>
										<label htmlFor='data' className='fw-bold text-start col-3'>
											Pricing Data
										</label>
										<button
											type='button'
											className='col-2 btn btn-success'
											onClick={() => addPricingInput(formIndex)}>
											+ Add
										</button>
									</div>
									<div className='col-12 mt-0'>
										<div
											style={{
												borderRadius: '20px',
												marginLeft: 'auto',
												marginRight: 'auto',
												padding: '8px',
											}}
											className='row mt-0 border border-2 rounded-sm'>
											{service.pricingData.map((owner: any, index: any) => (
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
													}}
													className='col-md-12 row'>
													<FormGroup
														className='col-6'
														labelClassName='mt-2'
														id={`ownerName_${index}`}
														label='Duration (mins)'
														isFloating>
														<Input
															placeholder='Duration'
															style={{
																padding: '10px',
																height: '50px',
																paddingTop: '25px',
															}}
															autoComplete='duration'
															className='mt-2'
															name='duration'
															value={
																owner.duration !== null &&
																!isNaN(owner.duration)
																	? owner.duration.toString()
																	: ''
															}
															onChange={(
																e: ChangeEvent<HTMLInputElement>,
															) => {
																const inputValue = e.target.value;
																if (/^\d*$/.test(inputValue)) {
																	handlePricingChange(
																		formIndex,
																		index,
																		'duration',
																		e.target.value,
																	);
																}
															}}
														/>
													</FormGroup>
													<FormGroup
														id={`ownerPercentage_${index}`}
														label='Price'
														className='mt-2 col-5'
														labelClassName='mt-0'
														isFloating>
														<Input
															placeholder='Price'
															autoComplete='price'
															name='price'
															style={{
																padding: '10px',
																height: '50px',
																paddingTop: '25px',
															}}
															value={
																owner.price !== null &&
																!isNaN(owner.price)
																	? owner.price.toString()
																	: ''
															}
															onChange={(
																e: ChangeEvent<HTMLInputElement>,
															) => {
																const inputValue = e.target.value;
																if (/^\d*$/.test(inputValue)) {
																	handlePricingChange(
																		formIndex,
																		index,
																		'price',
																		e.target.value,
																	);
																}
															}}
														/>
													</FormGroup>
													{service?.pricingData?.length > 1 && (
														<div
															style={{
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
															className='col-md-1'>
															<AntdButton
																className='btn'
																onClick={() =>
																	deletePricing(formIndex, index)
																}>
																<FaTrash />
															</AntdButton>
														</div>
													)}
												</div>
											))}
										</div>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12 d-flex justify-content-center row-gap-2'>
									<div className='row col-12 d-flex flex-row justify-content-between align-items-center'>
										<label
											htmlFor='availability'
											className='fw-bold text-start col-3'>
											Availability
										</label>
										<button
											type='button'
											className='col-2 btn btn-success'
											onClick={() => handleAddAvailability(formIndex)}>
											+ Add
										</button>
									</div>
									<div className='row col-12 d-flex justify-content-center row-gap-3 p-4 border border-2 rounded-2'>
										{/* eslint-disable-next-line react/no-array-index-key */}
										{service.availability.map((availability, index) => (
											<div
												/* eslint-disable-next-line react/no-array-index-key */
												key={index}
												className='row p-3 border border rounded-2'>
												<div className='col-md-4'>
													<label htmlFor='day'>Day</label>
													<select
														className='form-select'
														value={availability.day}
														onChange={(e) =>
															handleAvailabilityChange(
																formIndex,
																index,
																'day',
																e.target.value,
															)
														}>
														<option value=''>Select Day</option>
														{daysOfWeek.map((day) => (
															<option key={day} value={day}>
																{day}
															</option>
														))}
													</select>
												</div>
												<div className='col-md-4'>
													<label htmlFor='time'>Start Time</label>
													<input
														type='time'
														className='form-control'
														value={availability.time.startTime}
														onChange={(e) =>
															handleAvailabilityChange(
																formIndex,
																index,
																'time',
																e.target.value,
																'startTime',
															)
														}
													/>
												</div>
												<div className='col-md-4'>
													<label htmlFor='time'>End Time</label>
													<input
														type='time'
														className='form-control'
														value={availability.time.endTime}
														onChange={(e) =>
															handleAvailabilityChange(
																formIndex,
																index,
																'time',
																e.target.value,
																'endTime',
															)
														}
													/>
												</div>
												{service.availability?.length > 1 && (
													<div
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
														className='col-md-1'>
														<AntdButton
															className='btn'
															onClick={() =>
																deleteAvailability(formIndex, index)
															}>
															<FaTrash />
														</AntdButton>
													</div>
												)}
											</div>
										))}
									</div>
								</div>
								<div className='row col-12 mt-4 d-flex flex-column row-gap-2'>
									<p className='fw-bold'>Select Masseuses</p>
									{masseuses && masseuses?.length > 0 ? (
										<div className='d-flex w-100 justify-content-between align-items-center column-gap-3'>
											<div className='w-100 d-flex flex-row column-gap-2 overflow-auto p-2'>
												{masseuses &&
													masseuses.map((item) => (
														<div
															key={item.id}
															className='col-xxl-6 g-10 col-xl-6 col-md-4'>
															<Card
																className={`${
																	serviceForms[
																		formIndex
																	].masseuses.includes(item.id)
																		? 'border border-2 border-primary'
																		: ''
																}`}>
																<CardBody
																	style={{
																		display: 'flex',
																		justifyContent: 'center',
																	}}>
																	{item.profileImage ? (
																		<Avatar
																			src={`${process.env.REACT_APP_MEDIA_URL}${item?.profileImage}`}
																			size={100}
																			aria-label='Avatar'
																			className=''
																		/>
																	) : (
																		<Avatar
																			src={NoImage}
																			size={500}
																			aria-label='Avatar'
																		/>
																	)}
																</CardBody>
																<CardHeader>
																	<CardLabel>
																		<CardTitle
																			tag='div'
																			className='h5'>
																			{item?.firstName}{' '}
																			{item?.lastName}
																		</CardTitle>
																		<CardSubTitle
																			tag='div'
																			className='h6'>
																			{item?.email}
																		</CardSubTitle>
																	</CardLabel>
																</CardHeader>
																<CardFooter className='shadow-3d-container'>
																	<Button
																		onClick={() =>
																			handleCardClick(
																				formIndex,
																				item.id,
																			)
																		}
																		color='dark'
																		className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${
																			darkModeStatus
																				? 'light'
																				: 'dark'
																		}`}
																		size='lg'
																		tag='a'>
																		{serviceForms[
																			formIndex
																		].masseuses.includes(
																			item.id,
																		)
																			? 'De-select'
																			: 'Select'}
																	</Button>
																</CardFooter>
															</Card>
														</div>
													))}
											</div>
										</div>
									) : (
										<div className='row col-12 text-center p-2'>
											<p className='fw-bold'>
												No masseuses have been added yet.
											</p>
										</div>
									)}
									<div
										className='paginateSection'
										style={{
											padding: '10px 0px',
											display: 'flex',
											justifyContent: 'center',
										}}>
										{masseuseResponse?.pagination && (
											<Pagination
												className='pagination'
												current={onBoardServiceServiceProvidersCurrentPage}
												pageSize={masseuseResponse?.pagination.perPage}
												total={masseuseResponse?.pagination.totalDocs}
												onChange={(currentPage: number) => {
													dispatch(
														setOnBoardServiceServiceProvidersCurrentPage(
															currentPage,
														),
													);
												}}
												showSizeChanger={false}
											/>
										)}
									</div>
								</div>
								<div className='col-12 g-4 mt-4 col-8'>
									<button
										type='button'
										className='d-flex align-items-center justify-content-center col-12 py-3 btn btn-info border-1'
										onClick={() => handleSaveButtonClick(formIndex, service)}>
										Save
									</button>
									<AntdButton
										disabled={serviceForms?.length === 1}
										className='d-flex align-items-center col-12 py-4 btn btn-danger border-1 mt-2'
										onClick={() => {
											handleDeleteForm(formIndex);
										}}>
										Delete
									</AntdButton>
								</div>
							</div>
						))}
					</div>
					<div className='row col-12 g-4 mt-2'>
						<button
							type='button'
							className='d-flex align-items-center justify-content-center col-12 py-3 btn btn-primary border-1'
							onClick={addServiceForm}>
							+ Add
						</button>
					</div>
					<div className='mt-5 col-12 d-flex justify-content-between'>
						<div className=''>
							<AntdButton
								className='btn btn-danger border-1 px-5 py-2'
								onClick={() => {
									dispatch(setOnBoardingStep(2));
								}}>
								Previous
							</AntdButton>
						</div>
						<div className=''>
							<AntdButton
								className='btn btn-success border-1 px-5 py-2'
								onClick={handleThirdStepSubmit}>
								Submit
							</AntdButton>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default OnBoardServiceManagement;

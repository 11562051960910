// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-else-return */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

console.log('API Key:', process.env.REACT_APP_API_KEY);
console.log('VAPID Key:', process.env.REACT_APP_VAPID_KEY);

// Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDGBkm3Wd7EwqHvvJ0azbtj0IEF0Tl2l-I',
	authDomain: 'massagekey-1aa75.firebaseapp.com',
	projectId: 'massagekey-1aa75',
	storageBucket: 'massagekey-1aa75.appspot.com',
	messagingSenderId: '302636205587',
	appId: '1:302636205587:web:dbc9cf04769d0fe11061e6',
	measurementId: 'G-4MYGDEP61J',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
	// eslint-disable-next-line consistent-return
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			// Get FCM token
			return getToken(messaging, {
				vapidKey:
					'BJD5OPoRGCjSl5krhIBLArjCPpJUSWGlpuFE3YCJ0VJHpWbEl8n6Tae325G5LAhK4Md7XG7B0TVKiawfgKIMY3g',
			})
				.then((currentToken) => {
					if (currentToken) {
						localStorage.setItem('fcmToken', currentToken);
					} else {
						console.log('Failed to generate the app registration token.');
					}
				})
				.catch((err) => {
					console.log('An error occurred when requesting to receive the token.', err);
				});
		} else {
			console.log('User did not grant notification permission.');
		}
	});
};

// Call requestPermission when the app loads
requestPermission();

export const onMessageListener = (callback: any) => {
	const unsubscribe = onMessage(messaging, (payload: any) => {
		callback(payload);
	});

	return unsubscribe;
};

import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu, onBoardingMenu, reviewMenu } from '../menu';

import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import UserManagementHeader from '../pages/_layout/_headers/UserManagementHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import ReviewsManagementHeader from '../pages/_layout/_headers/ReviewsManagementHeader';
import NotificationManagementHeader from '../pages/_layout/_headers/NotificationManagementHeader';
import UserDetailHeader from '../pages/_layout/_headers/UserDetailHeader';
import MasseuseManagement from '../pages/_layout/_headers/MasseuseManagement';
import MasseuseDetailsHeader from '../pages/_layout/_headers/MasseuseDetailsHeader';
import MassageDetailHeader from '../pages/_layout/_headers/MassageDetailHeader';
import ServiceDetailsHeader from '../pages/_layout/_headers/ServiceDetailHeader';
import ServicesHeader from '../pages/_layout/_headers/Services';
import UpcomingBookingHeader from '../pages/_layout/_headers/BookingManagement';
import PastBookingHeader from '../pages/_layout/_headers/PastBookingHeader';
import BookingDetailHeader from '../pages/_layout/_headers/BookingDetailHeader';
import PaymentsManagement from '../pages/_layout/_headers/PaymentsManagementHeader';
import InvoicesManagement from '../pages/_layout/_headers/InvoicesManagement';
import InvoiceDetailHeader from '../pages/_layout/_headers/InvoiceDetail';
import GeneralPromotionHeader from '../pages/_layout/_headers/GeneralPromotionHeader';
import OptInHeader from '../pages/_layout/_headers/OptInHeader';
import MassageCenterReviewDetailHeader from '../pages/_layout/_headers/MassageCenterReviewDetailHeader';
import MasseuseReviewDetailHeader from '../pages/_layout/_headers/MasseuseReviewDetailHeader';
import ServiceDetailReviewHeader from '../pages/_layout/_headers/ServiceDetailReviewHeader';
import PastReviewDetailHeader from '../pages/_layout/_headers/PastReviewDetailHeader';
import FAQsHeader from '../pages/_layout/_headers/FAQsHeader';
import ContactQueriesHeader from '../pages/_layout/_headers/ContactQueries';
import ContactQueryDetailHeader from '../pages/_layout/_headers/ContactQueryDetail';
import AddBookingHeader from '../pages/_layout/_headers/AddBookingHeader';
import BookingsCalenderHeader from '../pages/_layout/_headers/BookingsCalender';
import ProfileHeader from '../pages/_layout/_headers/ProfileHeader';
import FeaturedDetailHeader from '../pages/_layout/_headers/FeaturedDetailHeader';
import ForgotPasswordHeader from '../pages/_layout/_headers/ForgotPasswordHeader';
import ResetPasswordHeader from '../pages/_layout/_headers/ResetPasswordHeader';
import OptInDetailHeader from '../pages/_layout/_headers/OptInDetailHeader';

const headers: RouteProps[] = [
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.forgotPassword.path, element: <ForgotPasswordHeader /> },
	{ path: `${demoPagesMenu.resetPassword.path}/:id`, element: <ResetPasswordHeader /> },
	{ path: 'auth-pages/sign-up', element: null },
	{ path: 'auth-pages/404', element: null },
	{ path: onBoardingMenu.onBoarding.path, element: null },
	{ path: reviewMenu.blank.path, element: null },
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{
		path: dashboardPagesMenu.userManagement.path,
		element: <UserManagementHeader />,
	},
	{
		path: `${dashboardPagesMenu.userDetail.path}/:id`,
		element: <UserDetailHeader />,
	},
	{
		path: dashboardPagesMenu.serviceManagement.path,
		element: <ServicesHeader />,
	},
	{
		path: `${dashboardPagesMenu.serviceDetail.path}/:id`,
		element: <ServiceDetailsHeader />,
	},
	{
		path: dashboardPagesMenu.massageDetails.path,
		element: <MassageDetailHeader />,
	},

	{
		path: dashboardPagesMenu.masseuseManagement.path,
		element: <MasseuseManagement />,
	},
	{
		path: `${dashboardPagesMenu.masseuseDetails.path}/:id`,
		element: <MasseuseDetailsHeader />,
	},

	{
		path: dashboardPagesMenu.masseuseDetails.path,
		element: <MasseuseDetailsHeader />,
	},
	{
		path: dashboardPagesMenu.bookingManagement.subMenu.bookings.path,
		element: <UpcomingBookingHeader />,
	},
	{
		path: dashboardPagesMenu.bookingManagement.subMenu.pastBookings.path,
		element: <PastBookingHeader />,
	},
	{
		path: `${dashboardPagesMenu.bookingManagement.subMenu.bookingDetail.path}/:id`,
		element: <BookingDetailHeader />,
	},
	{
		path: dashboardPagesMenu.bookingManagement.subMenu.bookingsCalender.path,
		element: <BookingsCalenderHeader />,
	},
	{
		path: dashboardPagesMenu.addBooking.path,
		element: <AddBookingHeader />,
	},
	{
		path: dashboardPagesMenu.reviewsManagement.path,
		element: <ReviewsManagementHeader />,
	},
	{
		path: `${dashboardPagesMenu.pastReviewDetail.path}/:id`,
		element: <PastReviewDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.massageCenterReviewDetail.path}/:id`,
		element: <MassageCenterReviewDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.masseuseReviewDetail.path}/:id`,
		element: <MasseuseReviewDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.serviceReviewDetail.path}/:id`,
		element: <ServiceDetailReviewHeader />,
	},
	{
		path: `${dashboardPagesMenu.optInDetails.path}/:id`,
		element: <OptInDetailHeader />,
	},
	{
		path: dashboardPagesMenu.promotions.subMenu.generalPromotions.path,
		element: <GeneralPromotionHeader />,
	},
	{
		path: dashboardPagesMenu.promotions.subMenu.optInPromotions.path,
		element: <OptInHeader />,
	},
	{
		path: dashboardPagesMenu.promotions.subMenu.optInPromotions.path,
		element: <OptInHeader />,
	},
	{
		path: dashboardPagesMenu.notificationManagement.path,
		element: <NotificationManagementHeader />,
	},
	{
		path: dashboardPagesMenu.financeManagement.subMenu.invoicesManagement.path,
		element: <InvoicesManagement />,
	},
	{
		path: dashboardPagesMenu.financeManagement.subMenu.paymentManagement.path,
		element: <PaymentsManagement />,
	},
	{
		path: `${dashboardPagesMenu.invoiceDetails.path}/:id`,
		element: <InvoiceDetailHeader />,
	},
	{
		path: dashboardPagesMenu.profileManagement.path,
		element: <ProfileHeader />,
	},
	{
		path: `${dashboardPagesMenu.featuredDetail.path}/:id`,
		element: <FeaturedDetailHeader />,
	},
	{
		path: dashboardPagesMenu.helpAndSupport.subMenu.faq.path,
		element: <FAQsHeader />,
	},
	{
		path: dashboardPagesMenu.helpAndSupport.subMenu.contactQueries.path,
		element: <ContactQueriesHeader />,
	},
	{
		path: `${dashboardPagesMenu.contactQueryDetail.path}/:id`,
		element: <ContactQueryDetailHeader />,
	},

	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;

import React, { useContext, useState } from 'react';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import { dashboardPagesMenu, onBoardingMenu, reviewMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import { setLogout } from '../../../redux/slice';
import Icon from '../../../components/icon/Icon';
import { ApiResponse, ProfileData } from '../../../services/DashboardApis/type';
import { useUpdateProfileMutation } from '../../../services/DashboardApis/dashboardApi';
import Spinner from '../../../components/bootstrap/Spinner';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const fcmToken = localStorage.getItem('fcmToken');

	const [updateProfile] = useUpdateProfileMutation();

	const logoutHandler = async () => {
		setIsLoading(true);
		// Set fcmToken to null directly, regardless of its current state
		const updatedFields: Partial<ProfileData & { fcmToken?: string | null }> = {
			fcmToken: null,
		};

		await updateProfile({ payload: updatedFields })
			.unwrap()
			.then((response) => {
				const { result, message: msg } = response as ApiResponse;
				if (result) {
					setIsLoading(false);
					dispatch(setLogout());
				}
			})
			.catch((error) => {
				message.error(error?.data?.message);
				setIsLoading(false);
			});
	};

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<>
					<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
					<Navigation menu={onBoardingMenu} id='aside-onBoarding-page' />
					<Navigation menu={reviewMenu} id='aside-onBoarding-page' />
				</>
			</AsideBody>
			<AsideFoot>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={logoutHandler}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									{isLoading && <Spinner isSmall inButton isGrow />}
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>Logout</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;

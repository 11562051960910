import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import Input from '../../../components/bootstrap/forms/Input';
import Label from '../../../components/bootstrap/forms/Label';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { setSetPasswordMailSend } from '../../../redux/slice';
import { ErrorResponseType } from '../../../services/ErrorResponseType/ErrorResponseType';
import { useForgotPasswordMutation } from '../../../services/AuthApis/authApi';
import { demoPagesMenu, reviewMenu } from '../../../menu';
import { RootState } from '../../../redux/store';
import Logo from '../../../assets/svg/LoginLogo.svg';
import { EmailValidation } from '../../../utils/utils';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { ApiResponse } from '../../../services/DashboardApis/type';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import BgImage from '../../../assets/bg.png';
import MailImage from '../../../assets/mail.png';

const useStyles = createUseStyles({
	root: {
		backgroundImage: `url(${BgImage})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		minHeight: '100%',
		minWidth: '100vw',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: '999px',
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		color: 'black',
		height: '100%',
	},
});

const ForgotPassword: FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { darkModeStatus } = useDarkMode();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [email, setEmail] = useState('');
	const { passwordVerificationMailSent } = useSelector((state: RootState) => state.admin);
	console.log("🚀 ~ passwordVerificationMailSent:", passwordVerificationMailSent)

	const [forgotPassword] = useForgotPasswordMutation();

	const forgotPasswordHandler = async () => {
		setIsLoading(true);
		try {
			if (email.length === 0) {
				message.error('please fill data in fields');
				return;
			}

			if (EmailValidation(email) === false) {
				message.error(`Please Enter Valid Email`);
				return;
			}

			await forgotPassword({ email })
				.unwrap()
				.then(async (res) => {
					const { result, message: msg } = res as ApiResponse;
					if (result) {
						setIsLoading(false);
						dispatch(setSetPasswordMailSend(true));
						// navigate(`../${demoPagesMenu.resetPassword.path}`);
						message.success(msg);
					} else {
						message.error(msg);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
					setIsLoading(false);
				});
		} catch (error) {
			const err = error as ErrorResponseType;
			message.error(err.data?.message);
			setIsLoading(false);
		}
	};

	return (
		<div className='row col-12 h-100 align-items-center justify-content-center'>
			{passwordVerificationMailSent ? (
				<div className={classes.root}>
					<div>
						<div className='h-100 d-flex justify-content-center'>
							<div>
								<div className='row col-12'>
									<div
										className='col-12'
										style={{
											fontSize: 'calc(1rem + 1vw)',
											textAlign: 'center',
											fontWeight: '500',
										}}>
										<img src={MailImage} alt='mail' width={100} height={100} />
										<p className='mt-4'>
											An email has been sent for password reset verification.
										</p>
									</div>
									<div
										className='col-12 d-flex justify-content-center'
										style={{
											fontSize: 'calc(0.8rem + 0.8vw)',
											textAlign: 'center',
										}}>
										<p>Please check your inbox to proceed.</p>
									</div>
									<Button
										color='danger'
										className='mx-auto'
										style={{ width: '20%' }}
										onClick={() => {
											dispatch(setSetPasswordMailSend(false));
										}}>
										Back
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
					<Card className='' data-tour='login-page'>
						<CardBody>
							<div className='text-center my-1'>
								<div
									className={classNames(
										'text-decoration-none fw-bold display-2',
										{
											'text-dark': !darkModeStatus,
											'text-light': darkModeStatus,
										},
									)}>
									<img src={Logo} alt='logo' height={100} width={100} />
									<p className='h4 fw-bold'>Massage Key</p>
								</div>
							</div>
							<div className='row col-12 p-0 m-0'>
								<div className='row' style={{ margin: '0 auto' }}>
									<form className='row'>
										<div className='row col-12 justify-content-center align-content-center py-5'>
											<div className='py-1'>
												<div className='text-center h1 fw-bold'>
													Forgot Password
												</div>
												<div className='text-center h4 text-muted mb-5'>
													Enter your registered mail
												</div>
												<Label htmlFor='Email' className='m-lg-2'>
													Email
												</Label>
												<Input
													autoComplete='username'
													placeholder='Enter email'
													value={email}
													onChange={(e: any) =>
														setEmail(e.target.value.trim())
													}
													className='mb-0 py-3'
												/>
												<Button
													isDisable={email.length === 0}
													color='warning'
													className='w-100 py-3 mt-5'
													onClick={forgotPasswordHandler}>
													{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
													Send verification
												</Button>
												<div className='col-12 mt-5'>
													<p className='' style={{ textAlign: 'center' }}>
														<a
															href=''
															className='fw-bold cursor-pointer text-decoration-none'
															onClick={() => {
																navigate(
																	`../${demoPagesMenu.login.path}`,
																);
															}}>
															Back to Login
														</a>
													</p>
												</div>
												<div className='col-12'>
													<p className='' style={{ textAlign: 'center' }}>
														<a
															href=''
															className='fw-bold cursor-pointer text-decoration-none'
															onClick={() => {
																navigate(
																	`../${demoPagesMenu.resetPassword.path}`,
																);
															}}>
															Reset-password
														</a>
													</p>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</CardBody>
					</Card>
				</div>
			)}
		</div>
	);
};
export default ForgotPassword;

import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../redux/store';
import { setLogout } from '../redux/slice';

const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).admin?.token;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
			headers.set('Accept', 'application/json');
			headers.set('Content-Type', 'application/json');
			headers.set('ngrok-skip-browser-warning', 'true');
		}
		return headers;
	},
});

const baseQueryWithInterceptor = async (
	args: unknown,
	api: BaseQueryApi,
	extraOptions: { single?: AbortSignal },
) => {
	const result = await baseQuery(args as FetchArgs, api, extraOptions);
	if (result?.error?.status === 401) {
		api.dispatch(setLogout());
	}
	return result;
};

const adminApi = createApi({
	reducerPath: 'adminApi',
	keepUnusedDataFor: 60,
	baseQuery: baseQueryWithInterceptor,
	endpoints: () => ({}),
});

export default adminApi;

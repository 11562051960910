// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-await-in-loop */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, ChangeEvent, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { message, Button as AntdButton, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FaTrash } from 'react-icons/fa';
import { UploadChangeParam } from 'antd/es/upload/interface';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../assets/svg/LoginLogo.svg';
import Input from '../../../components/bootstrap/forms/Input';
import Select from '../../../components/bootstrap/forms/Select';
import Label from '../../../components/bootstrap/forms/Label';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import MapPicker from '../../../components/MapComponent';
import Loader from '../../../components/Loader';
import OnBoardMasseuseManagement from './onboard-masseuse-management/OnBoardMasseuseManagement';
import OnBoardServiceManagement from './onboard-service-management/OnBoardServiceManagement';
import ReviewPage from '../review-page/ReviewPage';
import {
	useGetPresignedUrlMutation,
	usePostOnBoardingMutation,
	useGetOnBoardingMutation,
} from '../../../services/OnBoardingApis/OnBoardingApi';
import { Address, OnBoardingData, OnBoardingResponse } from '../../../services/OnBoardingApis/type';
import { RootState } from '../../../redux/store';
import { setOnBoardingStep } from '../../../redux/slice';
import OnBoardDocusign from './onboard-docusign/OnBoardDocusign';
import { dashboardPagesMenu } from '../../../menu';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { stateCityData } from '../../../utils/utils';

const OnBoardingPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [getPresignedUrl] = useGetPresignedUrlMutation();
	const [postOnBoarding] = usePostOnBoardingMutation();
	const [getOnBoarding] = useGetOnBoardingMutation();
	const { token, onBoardStep } = useSelector((state: RootState) => state.admin);
	const [isLoading, setIsLoading] = useState(false);
	const [isProfileLoading, setIsProfileLoading] = useState(false);
	const [isBannerLoading, setIsBannerLoading] = useState(false);
	const [isGalleryLoading, setIsGalleryLoading] = useState(false);
	const [isOverallLoading, setIsOverallLoading] = useState(false);
	const [fileData, setFileData] = useState<any[]>([]);
	const [onBoardData, setOnBoardData] = useState<OnBoardingData>();
	const [filesData, setFilesData] = useState<any[]>([]);
	const [docs, setDocs] = useState<any[]>([]);
	const [name, setName] = useState<string>('');
	const [businessID, setBusinessID] = useState<string>('');
	const [bannerImage, setBannerImage] = useState<string>('');
	const [profileImage, setProfileImage] = useState<string>('');
	const [gallery, setGallery] = useState<string[]>([]);
	const [owners, setOwners] = useState<any[]>([
		{ name: '', percentage: 0, email: '', number: '' },
	]);
	const [selectedProfileInfo, setSelectedProfileInfo] = useState<UploadChangeParam | null>(null);
	const [selectedBannerInfo, setSelectedBannerInfo] = useState<UploadChangeParam | null>(null);
	const [selectedGalleryInfo, setSelectedGalleryInfo] = useState<UploadChangeParam | null>(null);

	const [description, setDescription] = useState('');
	const [address, setAddress] = useState<Address>({
		street: onBoardData?.address?.street || '',
		area: onBoardData?.address?.area || '',
		city: onBoardData?.address?.city || '',
		state: onBoardData?.address?.state || 'USA',
		country: onBoardData?.address?.country || 'USA',
		zipCode: onBoardData?.address?.zipCode || '',
	});
	const cityOptions = stateCityData[address.state ?? ''] || [];

	const [location, setLocation] = useState<{
		type: string;
		coordinates: [number, number] | [];
	}>();

	const addOwnerRow = () => {
		const newOwner = {
			name: '',
			percentage: 0,
			email: '',
			number: '',
		};
		setOwners([...owners, newOwner]);
	};

	const deleteOwner = (index: number) => {
		const updatedOwners = owners.filter((_, idx) => idx !== index);
		setOwners(updatedOwners);
	};

	const uploadButton = (
		<button style={{ border: 0, background: 'none' }} type='button'>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</button>
	);

	const uploadProfileImage = async (info: UploadChangeParam): Promise<void> => {
		setIsProfileLoading(true);
		const selectedDoc = info.file;
		setFilesData(info.fileList);

		try {
			const response: any = await getPresignedUrl({
				filename: info.file.name,
				keyType: 'massageCenterProfile',
			}).unwrap();
			const presignedData = response?.data;
			const profileFilename = presignedData?.filename;

			await axios.put(`${presignedData.presignedUrl}`, selectedDoc);
			message.success('Profile image uploaded successfully');
			setProfileImage(profileFilename);
			setIsProfileLoading(false);
			return profileFilename;
		} catch (err: any) {
			message.error(err?.error || 'Something went wrong with profile image upload...');
			setIsProfileLoading(false);
			throw err;
		} finally {
			setIsProfileLoading(false);
		}
	};

	const uploadBannerImage = async (info: UploadChangeParam): Promise<void> => {
		setIsBannerLoading(true);
		const selectedDoc = info.file;
		setFileData(info.fileList);

		try {
			const response: any = await getPresignedUrl({
				filename: info.file.name,
				keyType: 'massageCenterBanner',
			}).unwrap();
			const presignedData = response?.data;
			const bannerFilename = presignedData?.filename;

			await axios.put(`${presignedData.presignedUrl}`, selectedDoc);
			message.success('Banner image uploaded successfully');
			setBannerImage(bannerFilename);
			setIsBannerLoading(false);
			return bannerFilename;
		} catch (err: any) {
			message.error(err?.error || 'Something went wrong with banner image upload...');
			setIsBannerLoading(false);
			throw err;
		} finally {
			setIsBannerLoading(false);
		}
	};

	const uploadGalleryImages = async (info: UploadChangeParam): Promise<void> => {
		setIsGalleryLoading(true);
		const uploadedFiles: string[] = [];

		try {
			for (const file of info.fileList) {
				const response: any = await getPresignedUrl({
					filename: file.name,
					keyType: 'gallery',
				}).unwrap();

				const presignedData = response?.data;
				const galleryFilename = presignedData?.filename;

				// Upload each file individually
				await axios.put(`${presignedData.presignedUrl}`, file.originFileObj);
				uploadedFiles.push(galleryFilename);
			}

			message.success('Gallery images uploaded successfully');
			setGallery((prevGallery) => [...prevGallery, ...uploadedFiles]);
			setIsGalleryLoading(false);
		} catch (err: any) {
			message.error(err?.error || 'Something went wrong with gallery image upload...');
			setIsGalleryLoading(false);
			throw err;
		} finally {
			setIsGalleryLoading(false);
		}
	};

	const handleNextButtonClick = async () => {
		setIsLoading(true);
		setIsOverallLoading(true);
		if (
			!name ||
			!businessID ||
			!owners ||
			!address ||
			!description ||
			!selectedProfileInfo ||
			!selectedBannerInfo ||
			!selectedGalleryInfo ||
			!location ||
			location?.coordinates?.length === 0
		) {
			message.warning('All fields are required');
			setIsLoading(false);
			setIsOverallLoading(false);
			return;
		}

		try {
			// Call all upload functions in parallel
			if (selectedProfileInfo && selectedBannerInfo && selectedGalleryInfo) {
				await Promise.all([
					uploadProfileImage(selectedProfileInfo),
					uploadBannerImage(selectedBannerInfo),
					uploadGalleryImages(selectedGalleryInfo),
				]);
			}
			setIsLoading(false);
			setIsOverallLoading(false);
		} catch (err) {
			message.error('Some uploads failed, please try again.');
			setIsLoading(false);
			setIsOverallLoading(false);
		} finally {
			setIsLoading(false);
			setIsOverallLoading(false);
		}
	};

	const handleOwnerChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { name, value } = e.target;
		if (name === 'number') {
			const phoneNumberPattern = /^[0-9]*$/;
			if (value?.length > 15 || !phoneNumberPattern.test(value)) {
				return;
			}
		}
		const parsedValue = name === 'percentage' ? parseFloat(value) : value;
		const updatedOwners = owners?.map((owner, idx) => {
			if (idx === index) {
				return { ...owner, [name]: parsedValue };
			}
			return owner;
		});
		setOwners(updatedOwners);
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const handleLocationSelect = (location: { lng: number; lat: number }) => {
		setLocation({
			type: 'Point',
			coordinates: [location.lng, location.lat],
		});
	};

	const handleFirstStepSubmit = () => {
		setIsLoading(true);
		try {
			postOnBoarding({
				onboardingStep: 1,
				name,
				businessID,
				owners,
				address,
				bannerImage,
				profileImage,
				gallery,
				location,
				description,
			})
				.unwrap()
				.then(async (res) => {
					const { result, message: msg } = res as OnBoardingResponse;
					if (result) {
						message.success(msg);
						dispatch(setOnBoardingStep(2));
						setIsLoading(false);
					} else {
						message.error(msg);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
					setIsLoading(false);
				});
		} catch (error) {
			message.error('something went wrong');
			setIsLoading(false);
		}
	};

	const showWarning = debounce(() => {
		message.warning('You can only upload a maximum of 5 images');
	}, 1000);

	const handleGallerySelect = (info: UploadChangeParam) => {
		if (info?.fileList?.length > 5) {
			// message.warning('You can only upload a maximum of 5 images');
			return;
		}
		setSelectedGalleryInfo(info);
	};

	const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
		setAddress({
			...address,
			city: e.target.value,
		});
	};

	const getOnBoardingData = useCallback(async () => {
		setIsLoading(true);
		await getOnBoarding({})
			.unwrap()
			.then(async (response) => {
				const { result, data } = response as OnBoardingResponse;
				if (result) {
					if (data?.isOnboard) {
						navigate(dashboardPagesMenu.dashboard.path);
					}
					setOnBoardData(data);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				setIsLoading(false);
				message.error(err?.data?.message);
			});
	}, [getOnBoarding]);

	useEffect(() => {
		if (profileImage && bannerImage && gallery?.length > 0 && onBoardStep === 1) {
			handleFirstStepSubmit();
		}
	}, [profileImage, bannerImage, gallery, onBoardStep]);

	useEffect(() => {
		getOnBoardingData();
	}, [getOnBoardingData]);

	useEffect(() => {
		if (onBoardData) {
			setName(onBoardData?.name);
			if (onBoardData?.address !== null) {
				setAddress(onBoardData?.address);
			}
			if (onBoardData?.owners !== null) {
				setOwners(onBoardData?.owners);
			}
			setDescription(onBoardData?.description);
			setBannerImage(onBoardData?.bannerImage);
			setProfileImage(onBoardData?.profileImage);
			if (onBoardData?.gallery !== null) {
				setGallery(onBoardData?.gallery);
			}
			setBusinessID(onBoardData?.businessID);
			setLocation({
				type: onBoardData.location?.type,
				coordinates: onBoardData.location?.coordinates as [number, number],
			});
		}
	}, [onBoardData]);

	useEffect(() => {
		if (!token) {
			return navigate('/');
		}
		return () => {};
	}, [navigate, token]);

	return (
		<div className='w-100 content-box'>
			<div>
				{onBoardStep === 5 ? (
					<div>
						<ReviewPage />
					</div>
				) : (
					<div className='row col-12'>
						<div className='col-5' style={{ backgroundColor: '#F6F6F6' }}>
							<div className='row col-12 d-flex justify-content-center align-items-center py-4'>
								<div className='col-6 py-5'>
									<p className='fs-2 fw-bold'>
										Apply to register Massage center on Platform
									</p>
									<div>
										<img src={Logo} alt='logo' height='300' width='250' />
									</div>
								</div>
							</div>
						</div>
						<div
							className='col-7 scrollbar'
							style={{ height: '100vh', overflowY: 'scroll' }}>
							{onBoardStep === 1 && (
								<div className='row'>
									{isLoading ||
									isProfileLoading ||
									isBannerLoading ||
									isGalleryLoading ||
									isOverallLoading ? (
										<div className='w-full'>
											<Loader />
										</div>
									) : (
										<div className='row col-12 justify-content-center'>
											<div
												className='col-10 py-5'
												style={{
													position: 'relative',
													left: '3rem',
												}}>
												<p className='fs-3 mb-5 fw-semibold'>
													Submit Application
												</p>
												<div className='row col-12 g-4 ownerName'>
													<div className='col-6'>
														<Label
															htmlFor='companyName'
															className='m-lg-2'>
															Company Name
														</Label>
														<FormGroup id='companyName' isFloating>
															<Input
																autoComplete='additional-name'
																onChange={(
																	e: ChangeEvent<HTMLInputElement>,
																) => setName(e.target.value)}
																value={name}
															/>
														</FormGroup>
													</div>
													<div className='col-6'>
														<Label
															htmlFor='businessID'
															className='m-lg-2'>
															Business EIN
														</Label>
														<FormGroup id='businessID' isFloating>
															<Input
																autoComplete='businessID'
																onChange={(
																	e: ChangeEvent<HTMLInputElement>,
																) => setBusinessID(e.target.value)}
																value={businessID}
															/>
														</FormGroup>
													</div>
												</div>
												<div className='row g-4 mt-2 col-12'>
													<div className='ownerData'>
														<div className='d-flex justify-content-between align-items-end'>
															<Label
																htmlFor='companyName'
																className='m-lg-2'>
																Owner
															</Label>
															<AntdButton
																className='btn btn-primary border-1 mb-1'
																onClick={addOwnerRow}>
																Add Owner
															</AntdButton>
														</div>
														{owners?.map((owner, index) => (
															// eslint-disable-next-line react/no-array-index-key
															<div
																className='ownerList d-flex flex-wrap flex-row gap-4'
																key={index as number}>
																<div className='row col-12'>
																	<div className='col-5'>
																		<FormGroup
																			id={`email_${index}`}
																			label='Email'
																			isFloating>
																			<Input
																				placeholder='Email'
																				autoComplete='email'
																				name='email'
																				value={
																					owner.email !==
																					null
																						? owner.email.toString()
																						: ''
																				}
																				onChange={(
																					e: ChangeEvent<HTMLInputElement>,
																				) =>
																					handleOwnerChange(
																						index,
																						e,
																					)
																				}
																			/>
																		</FormGroup>
																	</div>
																	<div className='col-5'>
																		<FormGroup
																			id={`name_${index}`}
																			label='Name'
																			isFloating>
																			<Input
																				placeholder='Name'
																				autoComplete='name'
																				name='name'
																				value={
																					owner.name !==
																					null
																						? owner.name.toString()
																						: ''
																				}
																				onChange={(
																					e: ChangeEvent<HTMLInputElement>,
																				) =>
																					handleOwnerChange(
																						index,
																						e,
																					)
																				}
																			/>
																		</FormGroup>
																	</div>
																	<div className='col-5 mt-2'>
																		<FormGroup
																			id={`ownerPercentage_${index}`}
																			label='Number'
																			isFloating>
																			<Input
																				placeholder='Number'
																				autoComplete='number'
																				name='number'
																				value={
																					owner.email !==
																					null
																						? owner.number.toString()
																						: ''
																				}
																				onChange={(
																					e: ChangeEvent<HTMLInputElement>,
																				) =>
																					handleOwnerChange(
																						index,
																						e,
																					)
																				}
																			/>
																		</FormGroup>
																	</div>
																	<div className='col-5 mt-2'>
																		<FormGroup
																			id={`ownerPercentage_${index}`}
																			label='Percentage'
																			isFloating>
																			<Input
																				placeholder='Percentage'
																				autoComplete='percentage'
																				name='percentage'
																				value={
																					owner.percentage !==
																						null &&
																					!isNaN(
																						owner.percentage,
																					)
																						? owner.percentage
																						: 0
																				}
																				onChange={(
																					e: ChangeEvent<HTMLInputElement>,
																				) =>
																					handleOwnerChange(
																						index,
																						e,
																					)
																				}
																			/>
																		</FormGroup>
																	</div>
																	{owners &&
																		owners?.length > 1 && (
																			<div className='col-1 mt-2'>
																				<AntdButton
																					className='btn'
																					onClick={() =>
																						deleteOwner(
																							index,
																						)
																					}>
																					<FaTrash />
																				</AntdButton>
																			</div>
																		)}
																</div>
															</div>
														))}
													</div>
													<div>
														<Label htmlFor='address' className='m-lg-2'>
															Company Address
														</Label>
														<div className='centerAddress border d-flex flex-wrap flex-row gap-4'>
															<div className='col-lg-5'>
																<Label
																	htmlFor='street'
																	className='mt-1'>
																	Street
																</Label>
																<FormGroup id='street' isFloating>
																	<Input
																		onChange={(
																			e: ChangeEvent<HTMLInputElement>,
																		) =>
																			setAddress({
																				...address,
																				street: e.target
																					.value,
																			})
																		}
																		value={
																			address?.street as string
																		}
																	/>
																</FormGroup>
															</div>
															<div className='col-lg-5'>
																<Label
																	htmlFor='area'
																	className='mt-1'>
																	Area
																</Label>
																<FormGroup id='area' isFloating>
																	<Input
																		onChange={(
																			e: ChangeEvent<HTMLInputElement>,
																		) =>
																			setAddress({
																				...address,
																				area: e.target
																					.value,
																			})
																		}
																		value={
																			address?.area as string
																		}
																	/>
																</FormGroup>
															</div>
															<div className='col-md-5'>
																<Label
																	htmlFor='country'
																	className='mt-1'>
																	Country
																</Label>
																<FormGroup id='country' isFloating>
																	<Select
																		ariaLabel='Country'
																		placeholder='Choose...'
																		list={[
																			{
																				value: 'USA',
																				text: 'USA',
																			},
																			// {
																			// 	value: 'Canada',
																			// 	text: 'Canada',
																			// },
																		]}
																		onChange={(
																			e: ChangeEvent<HTMLInputElement>,
																		) =>
																			setAddress({
																				...address,
																				country:
																					e.target.value,
																			})
																		}
																		value={
																			address?.country as string
																		}
																	/>
																</FormGroup>
															</div>
															<div className='col-md-5'>
																<Label
																	htmlFor='state'
																	className='mt-1'>
																	State
																</Label>
																<FormGroup id='state' isFloating>
																	<Select
																		ariaLabel='State'
																		placeholder='Choose...'
																		list={[
																			{
																				value: '',
																				text: 'Select state',
																			},
																			...Object.keys(
																				stateCityData,
																			).map((state) => ({
																				value: state,
																				text: state,
																			})),
																		]}
																		onChange={(
																			e: ChangeEvent<HTMLSelectElement>,
																		) =>
																			setAddress({
																				...address,
																				state: e.target
																					.value,
																			})
																		}
																		value={address.state || ''}
																	/>
																</FormGroup>
															</div>
															<div className='col-lg-5'>
																<Label
																	htmlFor='city'
																	className='mt-1'>
																	City
																</Label>
																<FormGroup id='city' isFloating>
																	<Select
																		ariaLabel='City'
																		placeholder='Choose...'
																		list={[
																			{
																				value: '',
																				text: 'Choose...',
																			},
																			...cityOptions.map(
																				(city) => ({
																					value: city,
																					text: city,
																				}),
																			),
																		]}
																		onChange={handleCityChange}
																		value={address?.city || ''}
																		disabled={
																			!cityOptions?.length
																		}
																	/>
																	{/* <Input
																		onChange={(
																			e: ChangeEvent<HTMLInputElement>,
																		) =>
																			setAddress({
																				...address,
																				city: e.target
																					.value,
																			})
																		}
																		value={
																			address?.city as string
																		}
																	/> */}
																</FormGroup>
															</div>
															<div className='col-md-5'>
																<Label
																	htmlFor='zip'
																	className='mt-1'>
																	Zip
																</Label>
																<FormGroup
																	id='zip'
																	label='Zip'
																	isFloating>
																	<Input
																		onChange={(
																			e: ChangeEvent<HTMLInputElement>,
																		) =>
																			setAddress({
																				...address,
																				zipCode:
																					e.target.value,
																			})
																		}
																		value={
																			address?.zipCode as string
																		}
																	/>
																</FormGroup>
															</div>
														</div>
													</div>
													<div className=''>
														<Label
															htmlFor='description'
															className='m-lg-2'>
															Description
														</Label>
														<FormGroup
															id='description'
															className='col-md-12 mb-3'>
															<Textarea
																onChange={(
																	e: ChangeEvent<HTMLInputElement>,
																) => setDescription(e.target.value)}
																value={description}
																maxLength={500}
															/>
															<div className='text-muted'>
																{description?.length}/500 characters
															</div>
														</FormGroup>
													</div>
												</div>
												<div className='row g-4 mt-2 col-12'>
													<div className='col-6'>
														<Label
															htmlFor='profileImage'
															className='m-lg-2'>
															Upload Company Logo
														</Label>
														<Upload
															style={{
																border: '2px dashed #ccc',
																borderRadius: '10px',
																width: '120px',
																height: '120px',
															}}
															accept='.jpg, .jpeg, .png,'
															listType='picture-card'
															beforeUpload={() => false}
															maxCount={1}
															onChange={(info) =>
																setSelectedProfileInfo(info)
															}
															rootClassName='w-10'>
															{filesData?.length >= 1
																? null
																: uploadButton}
														</Upload>
													</div>
													<div className='col-6'>
														<Label
															htmlFor='bannerImage'
															className='m-lg-2'>
															Upload Banner Image
														</Label>
														<Upload
															style={{
																border: '2px dashed #ccc',
																borderRadius: '10px',
																width: '120px',
																height: '120px',
															}}
															accept='.jpg, .jpeg, .png,'
															listType='picture-card'
															beforeUpload={() => false}
															maxCount={1}
															onChange={(info) =>
																setSelectedBannerInfo(info)
															}
															rootClassName='w-10'>
															{fileData?.length >= 1
																? null
																: uploadButton}
														</Upload>
													</div>
												</div>
												<div className='row g-4 mt-2 col-12'>
													<div>
														<Label
															htmlFor='bannerImage'
															className='m-lg-2'>
															Upload Gallery Images (max:5)
														</Label>
														<Upload
															listType='picture-card'
															beforeUpload={(file, fileList) => {
																const currentFileCount =
																	fileList.length +
																	(selectedGalleryInfo?.fileList
																		?.length || 0);

																if (currentFileCount > 5) {
																	showWarning(); // Debounced warning
																	return Upload.LIST_IGNORE;
																}
																return true;
															}}
															multiple
															onChange={handleGallerySelect}
															maxCount={5}>
															{selectedGalleryInfo &&
															selectedGalleryInfo?.fileList?.length >=
																5
																? null
																: uploadButton}
														</Upload>
													</div>
												</div>
												<Card className='mt-3'>
													<CardBody>
														<div className='mt-1 col-12'>
															<Label
																htmlFor='location'
																className='m-lg-2'>
																Pick a Location
															</Label>
															<MapPicker
																isInputVisible
																initialPosition={{
																	lng: -73.935242,
																	lat: 40.73061,
																}}
																onLocationSelect={
																	handleLocationSelect
																}
															/>
														</div>
													</CardBody>
												</Card>
												<div className='mt-4 col-12 d-flex justify-content-end align-content-center'>
													<AntdButton
														className='btn btn-success border-1 px-5 py-2'
														onClick={handleNextButtonClick}>
														Next
													</AntdButton>
												</div>
											</div>
										</div>
									)}
								</div>
							)}
							{onBoardStep === 2 && (
								<div className='row'>
									<OnBoardMasseuseManagement
										isFrom='onBoarding'
										getMasseuseHandler={() => Promise.resolve()}
									/>
								</div>
							)}
							{onBoardStep === 3 && (
								<div className='row'>
									<OnBoardServiceManagement />
								</div>
							)}
							{onBoardStep === 4 && (
								<div className='row'>
									<OnBoardDocusign />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default OnBoardingPage;

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import Spinner from './bootstrap/Spinner';

interface ILoaderProps {
	height?: string;
}

const Loader: FC<ILoaderProps> = ({ height }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: height || '70vh',
				fontSize: '18px',
			}}>
			<Spinner size={50} color='info' />
			Loading...
		</div>
	);
};

export default Loader;

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { demoPagesMenu, reviewMenu } from '../../../menu';
import Button from '../../../components/bootstrap/Button';
import { setIsBoarding, setLogout, setOnBoardingStep, setReviewStatus } from '../../../redux/slice';
import BgImage from '../../../assets/bg.png';
import { RootState } from '../../../redux/store';
import { useGetDashboardMutation } from '../../../services/DashboardApis/dashboardApi';
import { ApiResponse, DashboardResponse } from '../../../services/DashboardApis/type';
import { useReApplyApplicationMutation } from '../../../services/OnBoardingApis/OnBoardingApi';
import Spinner from '../../../components/bootstrap/Spinner';

const useStyles = createUseStyles({
	root: {
		backgroundImage: `url(${BgImage})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		minHeight: '100vh',
		minWidth: '100vw',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		color: 'black',
		height: '100%',
	},
});

const ReviewPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { reviewStatus } = useSelector((state: RootState) => state.admin);
	const [rejectionReason, setRejectionReason] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [status, setStatus] = useState({});

	const { token } = useSelector((state: RootState) => state.admin);

	const [getDashboard] = useGetDashboardMutation();
	const [reApplyApplication] = useReApplyApplicationMutation();

	const handleReApply = async () => {
		setIsLoading(true);
		await reApplyApplication({})
			.unwrap()
			.then((res) => {
				const { result, message: msg } = res as ApiResponse;
				if (result) {
					message.success(msg);
					dispatch(setOnBoardingStep(5));
					getDashboardData();
					setIsLoading(false);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err.data.message);
				message.error('Error in re-apply', err.data.message);
				setIsLoading(false);
			});
	};

	const getDashboardData = async () => {
		if (token) {
			await getDashboard({})
				.unwrap()
				.then((res) => {
					const { result, message: msg, data } = res as DashboardResponse;
					if (result) {
						dispatch(setIsBoarding(data?.isOnboard));
						dispatch(setReviewStatus(data?.status));
						setRejectionReason(data?.rejectionReason);
						setStatus((prev) => (prev ? { ...prev, status: data?.status } : prev));
						if (data?.status === 'approved') {
							navigate('/');
						}
					} else {
						message.error(msg);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	useEffect(() => {
		getDashboardData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, navigate, token]);

	useEffect(() => {
		if (status === 'approved' || reviewStatus === 'approved') {
			navigate('/');
		}
	}, [status, navigate, reviewStatus]);

	return (
		<div className={classes.root}>
			<PageWrapper title={reviewMenu.blank.text}>
				<Page className='h-100 d-flex justify-content-center'>
					<div>
						<div className='row col-12'>
							<div
								className='col-12'
								style={{
									fontSize: 'calc(1rem + 1vw)',
									textAlign: 'center',
									fontWeight: '500',
									color: 'red',
								}}>
								<p>
									{reviewStatus === 'moreClarity'
										? 'Please re-apply for more clarity'
										: reviewStatus === 'rejected'
											? 'Your application rejected'
											: 'We are reviewing your application to be our partner.'}
								</p>
							</div>
							<div className='mt-4'>
								<div
									className='col-12 d-flex flex-column justify-content-center'
									style={{
										fontSize: 'calc(0.8rem + 0.8vw)',
										textAlign: 'center',
									}}>
									{reviewStatus === 'moreClarity' ||
									reviewStatus === 'rejected' ? (
										<p>Reason: {rejectionReason}</p>
									) : null}

									{rejectionReason && (
										<Button
											color='danger'
											isDisable={isLoading}
											className='mx-auto'
											style={{ width: '20%' }}
											onClick={handleReApply}>
											{isLoading && <Spinner isSmall inButton isGrow />}{' '}
											Re-apply
										</Button>
									)}
								</div>
								{/* {reviewStatus === 'moreClarity' ? (
									<Button
										color='danger'
										className='mx-auto'
										style={{ width: '20%' }}
										onClick={handleLogout}>
										Re-apply
									</Button>
								) : (
									<Button
										color='danger'
										className='mx-auto'
										style={{ width: '20%' }}
										onClick={handleLogout}>
										Request Again
									</Button>
								)} */}
							</div>
						</div>
					</div>
				</Page>
			</PageWrapper>
		</div>
	);
};

export default ReviewPage;

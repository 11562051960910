import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import classNames from 'classnames';

import Input from '../../../components/bootstrap/forms/Input';
import Label from '../../../components/bootstrap/forms/Label';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { ErrorResponseType } from '../../../services/ErrorResponseType/ErrorResponseType';
import { useResetPasswordMutation } from '../../../services/AuthApis/authApi';
import { demoPagesMenu } from '../../../menu';
import Logo from '../../../assets/svg/LoginLogo.svg';
import { PasswordValidation } from '../../../utils/utils';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { ApiResponse } from '../../../services/DashboardApis/type';

const ResetPassword: FC = () => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const [resetPassword] = useResetPasswordMutation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
	};

	const resetPasswordHandler = async () => {
		setIsLoading(true);
		try {
			if (newPassword.length === 0 || confirmPassword.length === 0) {
				message.error('Please fill data in fields');
				setIsLoading(false);
				return;
			}

			if (PasswordValidation(newPassword) === false) {
				message.error(`Please Enter Valid Password`);
				setIsLoading(false);
				return;
			}

			if (newPassword.length < 6 || confirmPassword.length < 6) {
				message.error('New-password or Confirm-password must be 6 characters long');
				setIsLoading(false);
				return;
			}

			if (newPassword !== confirmPassword) {
				message.error('New-password and Confirm-password must be same');
				setIsLoading(false);
				return;
			}
			await resetPassword({ token: id, payload: { newPassword, confirmPassword } })
				.unwrap()
				.then(async (res) => {
					const { result, message: msg } = res as ApiResponse;
					if (result) {
						setIsLoading(false);
						message.success(msg);
						navigate(`../${demoPagesMenu.login.path}`);
					} else {
						message.error(msg);
						setIsLoading(false);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
					setIsLoading(false);
				});
		} catch (error) {
			const err = error as ErrorResponseType;
			message.error(err.data?.message);
			setIsLoading(false);
		}
	};

	return (
		<div className='row col-12 h-100 align-items-center justify-content-center'>
			<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
				<Card className='' data-tour='reset-password-page'>
					<CardBody>
						<div className='text-center my-1'>
							<div
								className={classNames('text-decoration-none fw-bold display-2', {
									'text-dark': !darkModeStatus,
									'text-light': darkModeStatus,
								})}>
								<img src={Logo} alt='logo' height={100} width={100} />
								<p className='h4 fw-bold'>Massage Key</p>
							</div>
						</div>
						<div className='row col-12 p-0 m-0'>
							<div className='row' style={{ margin: '0 auto' }}>
								<form className='row'>
									<div className='row col-12 justify-content-center align-content-center py-5'>
										<div className='py-1 mt-0'>
											<div className='text-center h1 fw-bold mt-0 mb-5'>
												Reset Password
											</div>
											<Label htmlFor='Password' className='m-lg-2'>
												New Password
											</Label>
											<div className='input-group mb-4'>
												<Input
													className='py-3'
													type={showPassword ? 'text' : 'password'}
													autoComplete='current-password'
													placeholder='Enter password'
													value={newPassword}
													onChange={(e: any) =>
														setNewPassword(e.target.value.trim())
													}
													onKeyPress={() => {}}
												/>
												<span
													className='input-group-text'
													role='button'
													tabIndex={0}
													onClick={togglePasswordVisibility}
													onKeyDown={(e) => {
														if (e.key === 'Enter' || e.key === ' ')
															togglePasswordVisibility();
													}}
													aria-label={
														showPassword
															? 'Hide password'
															: 'Show password'
													}>
													{!showPassword ? (
														<IoEyeOffOutline size={20} />
													) : (
														<IoEyeOutline size={20} />
													)}
												</span>
											</div>
											<Label htmlFor='Password' className='m-lg-2'>
												Confirm Password
											</Label>
											<div className='input-group mb-4'>
												<Input
													className='py-3'
													type={showConfirmPassword ? 'text' : 'password'}
													autoComplete='current-password'
													placeholder='Enter Confirm Password'
													value={confirmPassword}
													onChange={(e: any) =>
														setConfirmPassword(e.target.value.trim())
													}
													onKeyPress={() => {}}
												/>
												<span
													className='input-group-text'
													role='button'
													tabIndex={0}
													onClick={toggleConfirmPasswordVisibility}
													onKeyDown={(e) => {
														if (e.key === 'Enter' || e.key === ' ')
															toggleConfirmPasswordVisibility();
													}}
													aria-label={
														showConfirmPassword
															? 'Hide password'
															: 'Show password'
													}>
													{!showConfirmPassword ? (
														<IoEyeOffOutline size={20} />
													) : (
														<IoEyeOutline size={20} />
													)}
												</span>
											</div>
											<Button
												isDisable={
													newPassword.length === 0 ||
													confirmPassword.length === 0
												}
												color='info'
												className='w-100 py-3 mt-5 rounded-pill'
												onClick={resetPasswordHandler}>
												{isLoading && <Spinner isSmall inButton isGrow />}
												Reset
											</Button>
											<div className='col-12 mt-5'>
												<p className='' style={{ textAlign: 'center' }}>
													<a
														href='#0'
														className='fw-bold cursor-pointer text-decoration-none'
														onClick={() => {
															navigate(
																`../${demoPagesMenu.forgotPassword.path}`,
															);
														}}>
														Go back
													</a>
												</p>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
	);
};
export default ResetPassword;

import React, { useLayoutEffect, forwardRef, ReactElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import { dashboardPagesMenu, demoPagesMenu, onBoardingMenu, reviewMenu } from '../../menu';
import { RootState } from '../../redux/store';

interface IPageWrapperProps {
	title?: string;
	description?: string;
	children:
		| ReactElement<ISubHeaderProps>[]
		| ReactElement<IPageProps>
		| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ title, description, className, children }, ref) => {
		const navigate = useNavigate();
		const { token, isOnboarding, reviewStatus } = useSelector(
			(state: RootState) => state.admin,
		);
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
				process.env.REACT_APP_SITE_NAME
			}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		useEffect(() => {
			if (!token) {
				return navigate(`../${demoPagesMenu.login.path}`);
			}
			if (token && !isOnboarding) {
				return navigate(`../${onBoardingMenu.onBoarding.path}`);
			}
			if (token && isOnboarding && reviewStatus !== 'approved') {
				return navigate(`../${reviewMenu.blank.path}`);
			}
			if (
				token &&
				isOnboarding &&
				reviewStatus === 'approved' &&
				window.location.pathname === '/'
			) {
				return navigate(dashboardPagesMenu.dashboard.path);
			}
			return () => {};
		}, [token, isOnboarding, reviewStatus, navigate]);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;

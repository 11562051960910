import adminApi from '../adminApi';
import { ForgotPasswordRequestData, ResetPasswordRequestData } from '../DashboardApis/type';
import { LoginRequestBody, SignupRequestBody } from './types';

const AuthApi = adminApi.injectEndpoints({
	endpoints: (builder: any) => ({
		postLogin: builder.mutation({
			query: (payload: LoginRequestBody) => ({
				url: 'massage-center/login',
				method: 'POST',
				body: payload,
			}),
		}),
		postSignup: builder.mutation({
			query: (payload: SignupRequestBody) => ({
				url: 'massage-center/signup',
				method: 'POST',
				body: payload,
			}),
		}),
		forgotPassword: builder.mutation({
			query: (payload: ForgotPasswordRequestData) => ({
				url: `massage-center/forgot-password`,
				method: 'POST',
				body: payload,
			}),
		}),
		resetPassword: builder.mutation({
			query: ({ token, payload }: { token: string; payload: ResetPasswordRequestData }) => ({
				url: `massage-center/reset-password/${token}`,
				method: 'POST',
				body: payload,
			}),
		}),
	}),
});

export const {
	usePostLoginMutation,
	usePostSignupMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
} = AuthApi;

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import Input from './bootstrap/forms/Input';

type MapPickerProps = {
	initialPosition: { lat: number; lng: number };
	isInputVisible?: boolean;
	onLocationSelect?: (location: { lat: number; lng: number }) => void;
};

const redMarkerIcon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';

const containerStyle = {
	width: '100%',
	height: '400px',
};

const MapPicker: React.FC<MapPickerProps> = ({
	initialPosition,
	isInputVisible = true,
	onLocationSelect,
}) => {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
		libraries: ['places'], // Ensure 'places' library is included
	});

	const [selectedPosition, setSelectedPosition] = useState(initialPosition);
	const [inputValue, setInputValue] = useState('');
	const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
	const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const onMapClick = (event: google.maps.MapMouseEvent) => {
		if (event.latLng && onLocationSelect) {
			const position = {
				lng: event.latLng.lng(),
				lat: event.latLng.lat(),
			};
			setSelectedPosition(position);
			onLocationSelect(position);
		}
	};

	const handlePlaceSelect = (placeId: string) => {
		const geocoder = new google.maps.Geocoder();
		geocoder.geocode({ placeId }, (results, status) => {
			if (status === 'OK' && results && results[0].geometry?.location) {
				const position = {
					lng: results[0].geometry.location.lng(),
					lat: results[0].geometry.location.lat(),
				};
				setSelectedPosition(position);
				if (onLocationSelect) {
					onLocationSelect(position);
				}
				setInputValue(results[0].formatted_address || '');
			}
		});
		setSuggestions([]);
	};

	const fetchSuggestions = (value: string) => {
		if (!autocompleteServiceRef.current) {
			autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
		}

		if (value) {
			autocompleteServiceRef.current.getPlacePredictions(
				{ input: value },
				(predictions, status) => {
					if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
						setSuggestions(predictions);
					} else {
						setSuggestions([]);
					}
				},
			);
		} else {
			setSuggestions([]);
		}
	};

	useEffect(() => {
		if (!isLoaded) return;
		if (inputRef.current) {
			autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
		}
	}, [isLoaded]);

	if (!isLoaded) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			{isInputVisible && (
				<div style={{ position: 'relative' }}>
					<Input
						ref={inputRef}
						type='text'
						placeholder='Search for a location'
						value={inputValue}
						onChange={(e: any) => {
							setInputValue(e.target.value);
							fetchSuggestions(e.target.value); // Fetch suggestions as user types
						}}
						style={{
							marginBottom: '10px',
							width: '100%',
							padding: '10px',
							fontSize: '12px',
						}}
					/>
					{/* Display suggestions */}
					{suggestions.length > 0 && (
						<ul
							style={{
								position: 'absolute',
								zIndex: 1,
								background: '#fff',
								listStyle: 'none',
								width: '100%',
								padding: 0,
								margin: 0,
								border: '1px solid #ccc',
							}}>
							{suggestions.map((suggestion, index) => (
								<li
									key={index as number}
									role='button'
									tabIndex={0}
									style={{
										padding: '10px',
										cursor: 'pointer',
										listStyle: 'none',
									}}
									onClick={() => handlePlaceSelect(suggestion.place_id)}
									onKeyPress={(e) => {
										if (e.key === 'Enter' || e.key === ' ') {
											handlePlaceSelect(suggestion.place_id); // handle 'Enter' or 'Space' keys for selection
										}
									}}
									aria-label={`Select ${suggestion.description}`}>
									{suggestion.description}
								</li>
							))}
						</ul>
					)}
				</div>
			)}
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={selectedPosition}
				zoom={10}
				onClick={onMapClick}>
				<MarkerF position={selectedPosition} icon={redMarkerIcon} />
			</GoogleMap>
		</div>
	);
};

export default MapPicker;

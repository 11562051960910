import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setLogout } from '../../../redux/slice';

import Login from './Login';

const Logout = () => {
	// const dispatch = useDispatch();

	// useEffect(() => {
	//     dispatch(setLogout())
	// }, [dispatch])

	return <Login />;
};

export default Logout;

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Load your Stripe publishable key
const stripePromise = loadStripe(
	'pk_test_51P2yOw07oa7dpR8DJU6q8DKDhx78xweVkSJR0Zm8DuP879tivvrKugN0o3nyeclUoyh0BfPcPf3TRPQy1PmPw98400p5ByK2yZ',
);

const StripeProvider = ({ children }: any) => {
	return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
